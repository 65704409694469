<template>
  <div>
    <div class="content">
      <div class="contentTop contentTopOne">
        <div class="contentTopTitle">生产工单列表</div>
        <div class="contentTopRight">
          <div class="yida" @click="yida" v-premiSub="'生产工单_宜搭导入'">
            <img src="@/assets/images/production/dingding.png" alt="">
            <span>钉钉导入</span>
          </div>
          <el-tooltip v-premiSub="'拆分'" class="item" effect="dark" content="拆分" placement="top">
            <img src="@/assets/images/production/split.png" alt="" @click="split">
          </el-tooltip>
          <el-tooltip v-premiSub="'合并'" class="item" effect="dark" content="合并" placement="top">
            <img src="@/assets/images/production/merge.png" alt="" @click="merge">
          </el-tooltip>
          <el-tooltip v-premiSub="'生产工单_导入'" class="item" effect="dark" content="导入" placement="top">
            <img src="@/assets/images/production/leading-in.png" @click="exportDialog" alt="">
          </el-tooltip>
          <el-tooltip v-premiSub="'生产工单_导出'" class="item" effect="dark" content="导出" placement="top">
            <img src="@/assets/images/production/export.png" @click="exportFn" alt="">
          </el-tooltip>
          <el-tooltip v-premiSub="'生产工单_刷新'" class="item" effect="dark" content="刷新" placement="top">
            <img src="@/assets/images/production/renovate.png" alt="" @click="renovate">
          </el-tooltip>
          <el-input v-premiSub="'生产工单_搜索'" placeholder="工单号、图号、投产号" suffix-icon="el-icon-search" size="small" v-model="input2"
            @change="searchHandler">
          </el-input>
        </div>
      </div>
      <el-table ref="multipleTable" :data="tempData" tooltip-effect="dark" style="width: 100%"
        @selection-change="handleSelectionChange">
        <el-table-column type="selection" width="65">
        </el-table-column>
        <el-table-column prop="showId" label="序号" fixed="left" width="80">
        </el-table-column>
        <el-table-column prop="orderStatus" label="工单状态" width="130">
          <template slot="header" slot-scope={}>
            <el-dropdown trigger="click" size="medium" class="OrderStatus" @command="handleCommand">
              <span style="color:#909399">
                工单状态<i class="el-icon-arrow-down el-icon--right" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(item, index) in section" :key="index" :command="item">
                  {{ item }}
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </template>
          <template slot-scope="scope">
            <span
              :class="{ 'cell_Green': (scope.row.orderStatus === 1), 'cell_Yellow': (scope.row.orderStatus === 2) }"></span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 1)">正式</span>
            <span style="margin-left: 10px" v-if="(scope.row.orderStatus === 2)">意向</span>
          </template>
        </el-table-column>
        <el-table-column prop="priority" label="优先级" width="110">
        </el-table-column>
        <el-table-column prop="smallBatch" label="接单方式" width="150">
          <template slot-scope="scope">
            <el-checkbox class="piece" v-premiSub="'生产工单_散件'" v-model="scope.row.smallBatch" @change="pieceFN(scope.row)"></el-checkbox>散件
          </template>
        </el-table-column>
        <el-table-column prop="productionNo" label="投产号" width="150">
        </el-table-column>
        <el-table-column prop="orderNo" label="工单号" width="150">
        </el-table-column>
        <el-table-column prop="customerName" label="客户" width="120">
        </el-table-column>
        <el-table-column prop="productName" label="产品名称" width="150">
        </el-table-column>
        <el-table-column prop="partCode" label="图号" width="220">
        </el-table-column>
        <el-table-column prop="versionCode" label="版本号" width="150">
        </el-table-column>
        <el-table-column prop="plannedQuantity" label="生产数量" width="150">
        </el-table-column>
        <el-table-column prop="orderCompletionDateStr" label="需求日期" width="200">
        </el-table-column>
        <el-table-column prop="planStartTime" label="计划开始时间" width="200" label-class-name="GreenColor">
        </el-table-column>
        <el-table-column prop="planCompletionTime" label="计划完成时间" width="200" label-class-name="GreenColor">
        </el-table-column>
        <el-table-column prop="varianceDay" label="差异天数" width="200" label-class-name="GreenColor">
          <template slot-scope="scope">
            <div :class="{ bgColorGreen: (scope.row.overdueStatus === 1), bgColorRed: (scope.row.overdueStatus === 2) }">
              {{
                scope.row.varianceDay
              }}
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="totalFlex">
        <span>共{{ total }}条</span>
        <span>
          <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
            :current-page="currentPage1" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size=pageChange
            layout="prev, pager, next,sizes, jumper" :total=total>
          </el-pagination>
        </span>
      </div>
    </div>
    <resultDialog :name="name" :fileName="fileName" :resultMsg="resultMsg" :resultVisible="resultVisible"
      @reUpload="reUpload" :type="type" @update:resultVisible="closeFn" />
    <merge :isShow="isShow" :title="mergeTitle" @closeMerge="closeMerge" :mergeData="mergeData" @OKMerge="OKMerge" :mark="mark" />
    <split :isShowSplit="isShowSplit" :title="splitTitle" @closeSplit="closeSplit" :splitData="splitData" :mark="mark"
      @splitOk="splitOk" />
    <ImportDialog v-if="importVisible" :visible.sync="importVisible" templateName="生产工单导入模板"
      templateParam="productionOrder" :apis="apis" @success="handleProduction('update')" />
  </div>
</template>

<script>
const resultDialog = () => import("@/components/upload/resultDialog.vue")
const merge = () => import("@/components/merge.vue")
const split = () => import("@/components/split.vue")
import { getProductionData, importProduction, searchProductionData, importOrder, updateById,export1 } from '@/api/orders.js'
import { getDay } from "@/utils/date.js"
import ImportDialog from '@/components/dialog/ImportDialog.vue'
export default {
  name: "production",
  components: {
    resultDialog,
    merge,
    split,
    ImportDialog
  },
  data() {
    return {
      apis: {
        import: importProduction,//导入请求的接口
        // importTemplate:excelTemplate,//下载模板接口
      },
      importVisible: false,
      name: '',
      fileName: '',
      resultVisible: false,
      resultMsg: '',
      type: true,
      status: false,
      tableData: [],
      currentPage1: 1,
      total: 0,  //总条数，用于分组组件
      input2: '',
      section: [
        '正式',
        '意向'
      ],
      tempData: [],
      tempData2: [],
      limitNum: 1, //文件上传个数限制
      fileList: [], //文件列表
      pageChange: 10,   //当前页面多少条工单
      tempTotal: 0,  //总条数，但与分页组件无关
      searchStatus: false,
      searchData: [],
      multipleSelection: [],
      isShow: false,
      mergeTitle: '合并',
      isShowSplit: false,
      splitTitle: '拆分',
      tempArr: [],
      mergeData: [],
      splitData: [],
      mark:1
    }
  },
  methods: {
    async exportFn(){
      // 导出生产工单
      const res = await export1()
      this.filename = `生产工单 ${getDay()}.xlsx`
      this.url = window.URL.createObjectURL(res)
      const link = document.createElement('a')
      link.style.display = 'none'
      link.href = this.url
      link.setAttribute('download', this.filename)
      document.documentElement.appendChild(link)
      link.click()
      document.documentElement.removeChild(link)
      this.$message.success('导出成功！')
    },
    exportDialog() {
      this.importVisible = true
    },
    handleProduction() {
      this.renovate()
      this.importVisible = false
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
      let arr = []
      let arrData = []
      val.forEach(v => {
        arr.push(v.id)
        arrData.push({
          num: v.orderNo,
          date: v.orderCompletionDateStr,
          id: v.id
        })
      })
      this.tempArr = arr
      this.splitData = this.mergeData = arrData
    },
    // 拆分
    split() {
      if (this.tempArr.length < 1) {
        this.$message.warning({
          showClose: true,
          message:'请选择一个工单！',
          duration:0
        })
        return
      }
      if (this.tempArr.length > 1) {
        this.$message.warning({
          showClose: true,
          message:'不能同时拆分多个工单！',
          duration:0
        })
        return
      }
      this.isShowSplit = true
    },
    closeSplit() {
      this.isShowSplit = false
    },
    async splitOk() {
      this.isShowSplit = false
      await this.renovate()
    },
    // 合并
    merge() {
      if (this.tempArr.length < 2) {
        this.$message.warning({
          showClose: true,
          message:'请至少选择两个工单号进行合并！',
          duration:0
        })
        return
      }
      this.isShow = true
    },
    closeMerge() {
      this.isShow = false
    },
    async OKMerge() {
      this.isShow = false
      await this.renovate()
    },
    // 钉钉导入
    async yida() {
      // 约定一次最多100条
      const res = await importOrder(1, 100)
      if (res.code === 0) {
        this.$message.success('钉钉导入成功！')
      } else {
        this.$message.error({
          showClose: true,
          message: res.msg,
          duration:0
        })
      }
      await this.renovate()
    },
    // 散件
    async pieceFN(val) {
      let params = {
        id: val.id,
        smallBatch: val.smallBatch
      }
      const res = await updateById(params)
      if (res.code !== 0) {
        this.$message.error({
          showClose: true,
          message: res.msg,
          duration:0
        })
      }
    },
    // 组件一页显示多少条数据
    async handleSizeChange(val) {
      this.pageChange = val
      if (this.status) {
        this.tempData = this.tempData2.filter((v, i) => i < val)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.filter((v, i) => i < val)
      } else {
        const res = await getProductionData(1, val)
        if (res.code === 0) {
          const { records } = res.data
          this.tableData = records
          this.tempData = this.tableData
        }
      }
      this.currentPage1 = 1
    },
    // 选择页数
    async handleCurrentChange(val) {
      if (this.status) {
        // 如果数据经过筛选
        this.tempData = this.tempData2.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else if (this.searchStatus) {
        this.tempData = this.searchData.slice(val * this.pageChange - this.pageChange, val * this.pageChange)
      } else {
        const res = await getProductionData(val, this.pageChange)
        if (res.code === 0) {
          const { records } = res.data
          this.tableData = records
          this.tempData = records
        }
      }
      this.currentPage1 = val
    },
    async searchHandler() {
      const res = await searchProductionData(1, this.tempTotal, this.input2)
      // 搜索后的总条数
      this.searchData = res.data.records
      this.searchStatus = true
      this.pageChange = 10
      this.currentPage1 = 1
      this.tempData = this.searchData.filter((v, i) => i < 10)
      this.total = this.searchData.length
    },
    // 筛选状态
    async handleCommand(item) {
      const res = await getProductionData(1, this.tempTotal)
      const { records } = res.data
      this.status = true
      this.currentPage1 = 1
      this.pageChange = 10
      if (item === '正式') {
        this.tempData2 = records.filter(v => v.orderStatus === 1)
        this.tempData = this.tempData2.filter((v, i) => i < 10)
      } else if (item === '意向') {
        this.tempData2 = records.filter(v => v.orderStatus === 2)
        this.tempData = this.tempData2.filter((v, i) => i < 10)
      }
      this.total = this.tempData2.length
    },
    async renovate() {
      // 刷新，重新赋值数据
      const res = await getProductionData(1, 10)
      const { records, total } = res.data
      this.tableData = this.tempData = records
      this.total = this.tempTotal = total
      this.pageChange = 10
      this.currentPage1 = 1
      this.status = this.searchStatus = false
      this.input2 = ''
    },
    beforeAvatarUpload(file) {
      let fileArr = file.name.split('.')
      let suffix = fileArr[fileArr.length - 1]
      if (!/(xls|xlsx)/i.test(suffix)) {
        this.$message.warning({
          showClose: true,
          message:'文件格式不正确',
          duration:0
        })
        return false
      }
      if (file.size > 100 * 1024 * 1024) {
        this.$message.warning({
          showClose:true,
          message:'文件过大，请上传小于100MB的文件〜',
          duration:0
        })
        return false
      }
      return true
    },
    uploadByJsqd(file) {
      if (this.beforeAvatarUpload(file)) {
        this.fileList.name = file.name
        this.fileName = file.name
        this.fileList.url = ''
        let formdata = new FormData()
        formdata.append('file', file.raw)
        importProduction(formdata).then((res) => {
          if (res.code === 0) {
            this.resultVisible = true
            this.renovate()
            this.type = true
            this.resultMsg = ''
          } else if (res.data !== 0) {
            this.resultVisible = true
            this.type = false
            this.resultMsg = res.msg
          }
        }, (err) => {
          console.log('当前打印err错误:', err)
        })
      }
    },
    closeFn() {
      this.resultVisible = false
    },
    reUpload() {
      let temp = document.querySelectorAll('.el-upload')
      temp[0].click()
    }
  },
  async created() {
    await this.renovate()
  }
}
</script>

<style lang="sass" scoped>
@import './style/style.scss'
</style>

